import '../translations'
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { saveAs } from 'file-saver'
import { DEFAULT_LOCALE, SENTRY_DSN } from '../Constants'
import { Helmet } from 'react-helmet'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import BlocksRenderer from '../components/BlockEditor/BlocksRenderer'
import BlocksWrapper from '../components/BlockEditor/BlocksWrapper'
import styled from 'styled-components'
import { SignAction } from '../components/BlockEditor/RenderBlocks/SignBlock'
import { useTranslation } from 'react-i18next'
import { Style } from '../styles'
import IconButtonGroup from '../components/Button/IconButtonGroup'
import ReactTooltip from 'react-tooltip'
import AwardSuccess from '../components/Awards/AwardSuccess'
import AppLoader from '../components/Loaders/AppLoader'
import ContractHelper from '../helpers/Contracthelper'
import SignatureModal from '../components/Modals/SignatureModal'
import { ContractsController } from '../controllers'
import ContentBlockHelper from '../helpers/ContentBlockHelper'
import i18next from 'i18next'
import { ContentBlockVariables, Contract, ContractStatus, NumberFormat, Signature, SignatureResult, Workspace } from '../types'
import ContactPortal from '../components/ContactPortal/ContactPortal'
import ContactBlocksContainer from '../components/ContactPortal/ContactBlocksContainer'
import Icon from '../components/Icons/Icon'

Sentry.init({ dsn: SENTRY_DSN })

const ActionsBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  color: black;
  border-bottom: 1px solid ${Style.color.border};
  background-color: white;
  box-shadow: 0 0 16px 0 #13304213;
  padding: ${Style.spacing.x0_5};

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    ${SignAction} {
      width: 100%;
      text-align: center;
    }
  }

  @media print {
    display: none;
  }
`

interface IProps {
  workspace: Workspace
  contract: Contract
  numberFormat: NumberFormat
  currency: string
  variables: ContentBlockVariables
}

const Application = (props: IProps) => {
  const { workspace } = props
  const [contract, setContract] = React.useState(props.contract)
  const { t } = useTranslation()
  const award = React.useRef<AwardSuccess>(null)
  const [showSignatureModal, setShowSignatureModal] = React.useState(false)
  const contractSignable = ContractHelper.signable(contract)
  const urlSearchParams = new URLSearchParams(window.location.search);
  // Digital key that identifies the user
  const signKey = urlSearchParams.get('key')
  let unsignedUserSignature: Signature | null = ContentBlockHelper.getUnsignedUserSignature(contract.signatures, signKey)

  const signEnabled = contractSignable && unsignedUserSignature

  React.useEffect(() => {
    i18next.changeLanguage(contract?.contact?.locale || DEFAULT_LOCALE, (err, t) => { });
  }, [])

  const onDownloadClick = () => {
    saveAs(`/c/${contract.id}/download`)
  }

  const onSignClick = () => {
    setShowSignatureModal(true)
  }

  const onSignatureModalClose = () => {
    setShowSignatureModal(false)
  }

  const onSignatureModalSubmit = async (signature: SignatureResult) => {
    try {
      const response = await ContractsController.sign(contract.id, signature, signKey)
      setContract(response)

      if (award && award.current) award.current.show()
    } catch (ex) {
      console.error(ex)
    } finally {
      onSignatureModalClose()
    }
  }

  const renderActionbar = () => {
    if (contract.status === ContractStatus.DRAFT) {
      return (
        <ActionsBar>
          <p style={{ textAlign: 'center' }}>
            {t('Contract::This contract is a draft')}
          </p>
        </ActionsBar>
      )
    }

    return null
  }

  const getActions = () => {
    let actions: React.ReactNode[] = []

    if (contract.status !== ContractStatus.DRAFT) {
      actions.push(
        <IconButtonGroup
          buttons={[
            {
              icon: <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-alt-circle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm-32-316v116h-67c-10.7 0-16 12.9-8.5 20.5l99 99c4.7 4.7 12.3 4.7 17 0l99-99c7.6-7.6 2.2-20.5-8.5-20.5h-67V140c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12z"></path></svg>,
              tooltip: t('Contract::Download'),
              onClick: onDownloadClick
            }
          ]}
        />
      )
    }

    if (signEnabled) {
      actions.push(
        <SignAction onClick={onSignClick}>
          <Icon icon='signature' />
          {t('Contract::Sign contract')}
        </SignAction>
      )
    }

    return actions
  }

  return (
    <>
      <ContactPortal
        companyName={workspace?.business_name}
        title={contract?.name || t('Contract::Contract')}
        actions={getActions()}
      >
        <Helmet>
          <title>{contract?.name || t('Contract::Contract')}</title>
        </Helmet>

        <ScrollToTopOnMount />

        {renderActionbar()}

        <ContactBlocksContainer>
          <BlocksWrapper className='preview'>
            <BlocksRenderer
              blocks={contract.content_blocks}
              variables={props.variables}
              currency={props.currency}
              numberFormat={props.numberFormat}
              signKey={signKey}
              signatures={contract.signatures}
              onSignClick={signEnabled ? onSignClick : undefined}
            />
          </BlocksWrapper>
        </ContactBlocksContainer>

        <ReactTooltip effect='solid' />
        <AwardSuccess ref={award} />

        <SignatureModal
          show={showSignatureModal}
          onSubmit={onSignatureModalSubmit}
          onClose={onSignatureModalClose}
        />
      </ContactPortal>
    </>
  );
}


const ApplicationProvider = (props: IProps) => {
  return (
    <React.Suspense fallback={<AppLoader />}>
      <Application {...props} />
    </React.Suspense>
  )
}

export default ApplicationProvider