import * as React from 'react'

interface IProps {
	className?: string
	children: React.ReactNode
}

const BlocksWrapper = (props: IProps) => {
	return (
		<div className={`blocks-wrapper ${props.className}`}>
			{props.children}
		</div>
	)
}

export default BlocksWrapper;